
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        























































































































































































































.podcast-hero {
  --podcast-progress: 0%;
  --podcast-sticky-height: 8rem;

  @include mq(s) {
    --podcast-sticky-height: 12rem;
  }

  @include mq($until: m) {
    max-width: none;
    padding: 0 $spacing * 0.5 !important; // stylelint-disable-line
  }
}

.podcast-hero-inner {
  display: flex;
  flex-direction: column-reverse;
}

// Info
.podcast-hero__info {
  @extend %border-radius-small;

  overflow: hidden;
  padding: $spacing * 1.5;
  background: $c-white;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: $card-shadow;

  @include mq(l) {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 29rem;
    grid-column-gap: 5rem;
    align-items: center;
    padding: $spacing * 2.5;
  }
}

.podcast-hero__info__title {
  font-size: 2.8rem;

  @include mq(l) {
    font-size: 5rem;
  }
}

// Attendees
.podcast-hero__info__attendees {
  @include mq(l) {
    grid-row: 2 / 3;
    grid-column: 1 / 3;
  }
}

.podcast-hero__info__attendees__title {
  @extend %ff-alt;
  @extend %fw-bold;
  @extend %text-uppercase;

  display: flex;
  align-items: center;
  gap: $spacing * 0.5;
  font-size: 1.5rem;

  &::before,
  &::after {
    content: '';
    display: block;
    flex: 1;
    height: 1px;
    background: $c-light-grey;
  }
}

.podcast-hero__info__attendees__list {
  @extend %list-nostyle;

  @include mq(l) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: $spacing $spacing * 2;
  }
}

.podcast-hero__info__attendees__item {
  display: flex;
  gap: $spacing * 0.5;
  font-size: 1.6rem;
  line-height: 1.2;

  & + & {
    margin-top: $spacing * 0.5;
  }

  @include mq(l) {
    justify-content: center;
    max-width: 30%;

    & + & {
      margin-top: 0;
    }
  }
}

.podcast-hero__info__attendees__item__picture {
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;

  img {
    @include image-fit;
  }
}

.podcast-hero__info__attendees__item__details__headline {
  font-size: 1.5rem;
  font-style: italic;
}

.podcast-hero__info__attendees__item__details__name {
  @extend %fw-bold;
}

// Platforms
.podcast-hero__info__platforms {
  border-top: 1px solid $c-light-grey;

  @include mq(l) {
    display: grid;
    place-content: center;
    margin-top: 0;
    padding: $spacing * 1.5 $spacing * 2;
    border-top: 0;
    border-left: 1px solid $c-light-grey;
  }
}

.podcast-hero__info__platforms__title {
  @extend %ff-alt;
  @extend %fw-bold;
  @extend %text-center;
}

.podcast-hero__info__platforms__list {
  @extend %list-nostyle;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: $spacing * 0.5;

  @include mq(l) {
    padding: 0 $spacing * 1.5;
  }
}

.podcast-hero__info__platforms__item__link {
  display: block;
  transition: transform 0.2s $ease-out;

  span {
    @extend %visually-hidden;
  }

  &:hover,
  &:focus-visible {
    transform: scale(1.1);
  }
}

// Video
/* stylelint-disable selector-max-combinators, selector-max-compound-selectors, max-nesting-depth */
.podcast-hero__video {
  @extend %border-radius-small;

  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  background: $c-light-grey;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;

  ::v-deep .video__poster,
  ::v-deep .v-vlite {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .has-sticky & .g-video {
    position: fixed;
    z-index: 3;
    top: var(--header-height-down);
    left: 0;
    width: auto;
    height: var(--podcast-sticky-height);
    animation: slide-down 0.3s $ease-out forwards;

    ::v-deep {
      .v-vlite,
      .video__poster {
        border-radius: 0;
      }

      // Progress bar, play buttons etc
      // stylelint-disable-next-line selector-class-pattern
      .v-controlBar,
      // Video title
      .ytp-chrome-top,
      // Suggested videos
      .ytp-pause-overlay-container {
        display: none;
      }

      .v-overlay {
        height: 100%;
      }

      .video__poster__play {
        --play-width: 4.2rem;
        --icon-width: 3.2rem;
      }

      .video__poster__play__icon {
        --icon-width: 3.2rem;
      }

      .video__poster__play__label {
        @extend %visually-hidden;
      }
    }

    @include mq(l) {
      ::v-deep .video__poster__play {
        padding: 0;
      }
    }

    @include mq(m) {
      left: initial;
    }
  }
}
/* stylelint-enable selector-max-combinators, selector-max-compound-selectors, max-nesting-depth */

.podcast-hero__video__pause {
  @include center-xy;

  z-index: 1;
  display: none;
  fill: $c-white;
  pointer-events: none;
  touch-action: none;

  .has-sticky & {
    display: block;
  }
}

.podcast-hero__video__picture {
  @include get-all-space;

  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

@keyframes slide-down {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0%);
  }
}
