
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        











































































$bp: 'm';
$br: 8px;

.magazine-stories {
  @include mq($until: $bp) {
    padding-top: 0;
    padding-bottom: 0;

    .magazine-landing & {
      margin-top: 0;
    }
  }
}

.magazine-stories-outer {
  .podcast & {
    @include fluid(
      padding-top,
      (
        xxs: 20px,
        l: 120px,
      )
    );

    border-top: $c-light-grey 1px solid;
  }
}

.magazine-stories-inner {
  margin: 0 calc(#{$spacing} * -1);
  border-radius: $br;
  box-shadow: $card-shadow;

  @include mq($bp) {
    display: flex;
    flex-direction: row-reverse;
    margin: 0;
  }
}

.magazine-stories__content {
  padding: $spacing * 2;
  background: $c-white;
  border-radius: $br $br 0 0;

  @include mq($bp) {
    flex: 50% 1 1;
    border-radius: 0 $br $br 0;
  }

  @include mq(l) {
    padding: $spacing * 3 $spacing * 4.5 $spacing * 2.5 $spacing * 3.5;
  }
}

.magazine-stories__content__title {
  color: $c-mustard;
}

.magazine-stories__content__text {
  margin: $spacing * 1.5 0;

  @include mq($bp) {
    margin: $spacing * 2 0;
  }
}

.magazine-stories__picture {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 70%;
  background: $c-mustard;
  border-radius: 0 0 $br $br;

  img {
    @include center-xy;

    width: 95%;
    transform: translate(-50%, -55%) rotate(-6deg);
    transform-origin: center;
  }

  @include mq($bp) {
    flex: 50% 1 1;
    height: auto;
    padding-bottom: 0;
    border-radius: $br 0 0 $br;

    img {
      // width: 100%;
      transform: translate(-50%, -50%) rotate(-16deg);
    }
  }
}
